<template>
  <div class="content-container">
    <PageTitle :title="$t('FAQ.TITLE')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('CREATE_FAQ.NEW') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')"
                :route-name="ROUTE_NAMES_CMS.FAQ"
    />

    <!-- Form -->
    <FormWrapper v-if="originalQuestion"
                 :send-form-data="validateData"
                 class="page-form"
                 :on-form-data-update="updatedForm">
      <template #form-content>

        <!-- question -->
        <InputField :field-title="$t('CREATE_FAQ.QUESTION')"
                    :field-name="'question'"
                    :rules="'required'"
                    :type="'text'"
                    :value="originalQuestion.question"
                    :placeholder="$t('CREATE_FAQ.QUESTION_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="''"
                    class="form-element"/>

        <div class="rich-text-field-wrapper">
          <RichTextField :field-title="$t('CREATE_FAQ.ANSWER')"
                         :input-callback="instructionInput"
                         :value="originalQuestion.answer"
                         :error-text="richTextError"
          />
        </div>

        <!-- Type of question -->
        <Dropdown id="category"
                  :field-title="$t('CREATE_FAQ.CATEGORY')"
                  :dropdown-options="categories"
                  :placeholder="$t('CREATE_FAQ.CATEGORY_PLACEHOLDER')"
                  :field-name="'faqCategoryId'"
                  :selected-option="categoryLookup(originalQuestion.faqCategoryId)"
                  :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                  :callback="() => {}"
                  :api-error-text="''"
                  class="form-element"/>

        <!-- Status -->
        <Dropdown id="status"
                  :field-title="$t('CREATE_FAQ.STATUS')"
                  :dropdown-options="status"
                  :placeholder="$t('CREATE_FAQ.STATUS_PLACEHOLDER')"
                  :field-name="'isActivated'"
                  :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                  :callback="() => {}"
                  :selected-option="statusLookup(originalQuestion.isActivated)"
                  :api-error-text="''"
                  class="form-element"/>

        <Checkbox
          :field-title="''"
          :field-name="'isTopTen'"
          :rules="''"
          :value="originalQuestion.isTopTen === 1 ? true : false"
          :text="$t('CREATE_FAQ.TOP_TEN')"/>


      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t('CREATE_CLASS_TEXT') }}
        </button>
      </template>

    </FormWrapper>


  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import Dropdown from '@/components/yo-form/Dropdown'
import Checkbox from '@/components/yo-form/Checkbox'
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { EDIT_FAQ } from '@/store/modules/cms/actions'
import nl from '@/utils/language/nl.json'
import ApiHandler from '../../../utils/handlers/ApiHandler'
import { watch } from '@vue/runtime-core'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'

export default {
  name: 'EditFaq',
  components: {
    BackButton,
    PageTitle,
    InputField,
    FormWrapper,
    Checkbox,
    Dropdown,
    RichTextField,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const topTenValue = ref(false)
    const route = useRoute()
    const questionId = route.params.id
    const currentFormData = ref({})
    const originalQuestion = ref(null)

    const instructionValue = ref('')
    const richTextError = ref('')

    ApiHandler.get(`faq/get-question/${questionId}`).then(result => {
      originalQuestion.value = result.data
      instructionValue.value = result.data.answer
    })

    /** Categories **/
    const categories = [{ id: 1, name: 'Algemeen' }, { id: 2, name: 'Mijn MediaMasters' }, { id: 3, name: 'Technisch' }, { id: 4, name: 'Account' }]



    function categoryLookup(id) {
      return categories.find(value => value.id === id)
    }

    function statusLookup(id) {
      return status.find(value => value.id === id)
    }

    /** Group Dropdown **/
    const status = [
      {
        id: 0,
        name: nl.CONCEPT,
      },
      {
        id: 1,
        name: nl.PUBLISHED,
      },
    ]

    function reverseLookup(list, name) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].name === name) {
          return list[i].id
        }
      }
    }

    /** Sign Language **/ // TODO: Add translation string hre.

    /** Form **/
    function validateData(data) {
      data.id = originalQuestion.value.id
      data.answer = instructionValue.value
      if (!data.answer || data.answer === '') {
        richTextError.value = nl.CREATE_FAQ.NO_ANSWER_GIVEN
        return false
      }
      data.isTopTen = data.isTopTen ?? originalQuestion.isTopTen ?? false
      data.isActivated = reverseLookup(status, data.isActivated)
      data.faqCategoryId = reverseLookup(categories, data.faqCategoryId)
      createQuestion(data)
    }

    /** Create Class **/
    function createQuestion(data) {
      store.dispatch(EDIT_FAQ, data).then(response => {
        if (response) {
          router.push({ name: ROUTE_NAMES_CMS.FAQ })
        }
      })
    }

    function updatedForm(data) {
      currentFormData.value = data
    }

    function instructionInput(value) {
      instructionValue.value = value
    }

    return {
      ROUTE_NAMES_CMS,

      /** Group Dropdown **/
      status,

      categories,
      topTenValue,
      updatedForm,
      originalQuestion,
      categoryLookup,
      statusLookup,
      /** Form **/
      validateData,
      instructionInput,
      richTextError,

    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

</style>
